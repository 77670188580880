import FocusTrap from "focus-trap-react";
import NavigationItem from "./NavigationItem";
import React, { FunctionComponent, useState } from "react";
import DropdownNavigationItem from "./DropdownNavigationItem";

import Close from "./close.svg";
import { ReactComponent as HomeIcon } from "./home-icon.svg";
import Logo from "../../images/logo.svg";
import LogoWhite from "./logo-white.svg";

type Props = {
  open: boolean;
  closeNavigation: () => void;
};

const Navigation: FunctionComponent<Props> = ({ open, closeNavigation }) => {
  const [dropdownOpen, setDropdownOpen] = useState<null | string>(null);

  const className = () => {
    const classNames = [
      "fixed top-0 left-full w-full h-full z-20 t-0 flex flex-col bg-green md:bg-white md:block md:transform-none md:static md:opacity-100 md:visible pt-1",
    ];

    if (open) {
      classNames.push("transition-navigation-open -translatex-full visible");
    } else {
      classNames.push("transition-navigation-closed opacity-0 invisible");
    }

    return classNames.join(" ");
  };

  const isDropdownOpen = (id: string) => id === dropdownOpen;

  return (
    <FocusTrap active={open}>
      <nav id="drawer-nav" className={className()}>
        <div className="container flex justify-between items-center py-6 md:hidden">
          <img src={LogoWhite} alt="HepBMD Logo" />
          <button className="flex items-center" onClick={closeNavigation}>
            <span className="text-white uppercase text-sm mr-2 mt-1">
              Close
            </span>
            <img src={Close} />
          </button>
        </div>
        <div className="flex flex-1 md:block md:px-4 md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl md:mx-auto">
          <div className="w-1/6 h-full bg-white shadow-dark md:hidden"></div>
          <ul className="Navigation w-5/6 h-full pl-10 pr-4 md:-mx-4 md:w-auto md:px-0 md:flex md:items-center md:justify-between lg:-mx-4">
            <NavigationItem to="/" label="Home">
              <div className="items-center hidden md:flex md:w-48">
                <img src={Logo} alt="HepBMD Logo" className="inline-block" />
                <HomeIcon className="transition-fill hover:fill-green home w-10 ml-8" />
              </div>
              <span className="md:hidden">Home</span>
            </NavigationItem>
            <NavigationItem
              className="lg:ml-10"
              to="/hepatitis-b-risk"
              dropdownId="hepatitis-b-risk"
              setDropdownOpen={setDropdownOpen}
              isDropdownOpen={isDropdownOpen("hepatitis-b-risk")}
              dropdownItems={[
                <DropdownNavigationItem
                  to="/hepatitis-b-risk/screening"
                  key="screening"
                >
                  Why Screen?
                </DropdownNavigationItem>,
              ]}
            >
              Who is at Risk?
            </NavigationItem>
            <NavigationItem to="/hepatitis-b-screening-diagnosis">
              Screening &amp; Diagnosis
            </NavigationItem>
            <NavigationItem to="/hepatitis-b-treatment">
              Treating Patients
            </NavigationItem>
            <NavigationItem
              className="md:px-6"
              to="/hepatitis-b-management"
              dropdownId="hepatitis-b-management"
              setDropdownOpen={setDropdownOpen}
              isDropdownOpen={isDropdownOpen("hepatitis-b-management")}
              dropdownItems={[
                <DropdownNavigationItem
                  to="/hepatitis-b-management/patient-education"
                  key="hepatitis-b-management/patient-education"
                >
                  Patient Education
                </DropdownNavigationItem>,
              ]}
            >
              Managing Hep&nbsp;B
            </NavigationItem>
          </ul>
        </div>
      </nav>
    </FocusTrap>
  );
};

export default Navigation;
