import React, { FunctionComponent } from "react";
import { mergeClassNames } from "../helpers";

type Props = {
  className?: string;
  name?: string;
  paddingTop?: string;
  paddingBottom?: string;
};
const Slab: FunctionComponent<Props> = ({
  className,
  children,
  name,
  paddingTop = "pt-16",
  paddingBottom = "pb-12",
}) => {
  return (
    <>
      {name && <div id={name}></div>}
      <div
        className={mergeClassNames(`${paddingTop} ${paddingBottom}`, className)}
      >
        <div className="container">{children}</div>
      </div>
    </>
  );
};
export default Slab;
