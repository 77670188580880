import { Link } from "gatsby";
import { isMobile } from "../../helpers";
import React, { FunctionComponent, Dispatch, SetStateAction } from "react";

import "./NavigationItem.scss";

type Props = {
  className?: string;
  to: string;
  label?: string;
  dropdownId?: string;
  setDropdownOpen?: Dispatch<SetStateAction<string | null>>;
  isDropdownOpen?: boolean;
  dropdownItems?: JSX.Element[];
};

const NavigationItem: FunctionComponent<Props> = ({
  className,
  children,
  to,
  label,
  dropdownId,
  setDropdownOpen,
  isDropdownOpen,
  dropdownItems,
}) => {
  const openDropdown = (
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (!setDropdownOpen || !dropdownId || isDropdownOpen || !isMobile()) {
      return;
    }

    event.preventDefault();
    setDropdownOpen(dropdownId);
  };

  const ariaAttributes = () => {
    const attributes: { [key: string]: string } = {};

    if (label) {
      attributes["aria-label"] = label;
    }

    if (dropdownItems) {
      attributes["aria-haspopup"] = "true";
    }

    if (isDropdownOpen) {
      attributes["aria-expanded"] = "true";
    }

    return attributes;
  };

  const classNames = () => {
    const classNames = [className, "NavigationItem", "relative"];

    if (isDropdownOpen) {
      classNames.push("NavigationItem--dropdown-open");
    }

    if (dropdownItems) {
      classNames.push("NavigationItem--has-dropdown");
    }

    return classNames.join(" ");
  };

  return (
    <li className={classNames()}>
      <Link
        className="NavigationItem__anchor py-4 text-white text-right block md:text-center md:p-4 md:uppercase md:text-black transition-color md:hover:text-green"
        to={to}
        {...ariaAttributes()}
        onClick={openDropdown}
        activeClassName="NavigationItem__anchor--active"
      >
        {children}
      </Link>
      {dropdownItems ? (
        <ul className={`NavigationItem__dropdown`}>{dropdownItems}</ul>
      ) : null}
    </li>
  );
};

export default NavigationItem;
