import React, { FunctionComponent } from "react";
import { mergeClassNames } from "../helpers";

type Props = {
  className?: string;
  color?: string;
};
const Paragraph: FunctionComponent<Props> = ({
  children,
  className,
  color,
}) => {
  let colorClass = "text-black";
  if (color !== undefined) {
    colorClass = `text-${color}`;
  }

  return <p className={mergeClassNames(colorClass, className)}>{children}</p>;
};
export default Paragraph;
