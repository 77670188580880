import React, { FunctionComponent } from "react";
import Link from "../Link/Link";

import "./SecondaryNav.scss";

const NOOP = () => {};

const SecondaryNavigation: FunctionComponent = () => {
  return (
    <nav className="bg-grey-dark secondaryNav">
      <div className="container flex container_secondary_nav">
        <ul className="ul_container">
          <li className="text-white text-xs px-2 sm:px-4 education_txt">
            <span>
              Education for
              <br className="mobile_br" /> Healthcare Professionals
            </span>
          </li>
          <li className="text-xs px-2 sm:px-4 patient_site_link">
            <a
              className="text-white underline"
              href="https://hepb.com"
              target="_blank"
            >
              Patient&nbsp;Site
            </a>
          </li>
        </ul>

        <div className="hepD_link">
          <a href="https://hepdelta.com" target="_blank">
            <p>
              For hep D:
              <br className="mobile_br" /> <span>hepdelta.com</span>
            </p>
          </a>
        </div>
      </div>
    </nav>
  );
};

export default SecondaryNavigation;
