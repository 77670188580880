import Navigation from "./Navigation/Navigation";
import MobileHeader from "./MobileHeader/MobileHeader";
import React, { FunctionComponent, useState, useEffect } from "react";
import SecondaryNavigation from "./SecondaryNavigation/SecondaryNavigation";

const Header: FunctionComponent = () => {
  const [navigationOpen, setNavigationOpen] = useState(false);

  useEffect(() => {
    const handleEscape = (event: KeyboardEvent) => {
      if (event.keyCode === 27) {
        setNavigationOpen(false);
      }
    };
    const handleResize = () => setNavigationOpen(false);

    if (navigationOpen) {
      window.addEventListener("keydown", handleEscape);
      // Close navigation on resize
      window.addEventListener("resize", handleResize);

      return function cleanup() {
        window.removeEventListener("keydown", handleEscape);
        window.removeEventListener("resize", handleResize);
      };
    }
  }, [navigationOpen]);

  const openNavigation = () => {
    setNavigationOpen(true);
  };

  const closeNavigation = () => {
    setNavigationOpen(false);
  };

  return (
    <header>
      <SecondaryNavigation />
      <MobileHeader openNavigation={openNavigation} open={navigationOpen} />
      <Navigation closeNavigation={closeNavigation} open={navigationOpen} />
    </header>
  );
};

export default Header;
