import { mergeClassNames } from "../../helpers";
import React, { FunctionComponent } from "react";

type Props = {
  className?: string;
};

const Line: FunctionComponent<Props> = ({ className }) => {
  return <div className={mergeClassNames("h-2px bg-black", className)}></div>;
};

export default Line;
