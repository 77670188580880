import React, { FunctionComponent, useEffect, useRef } from "react";
import Rellax from "rellax";
import { prefersReducedMotion } from "../../helpers";

type Props = {
  percentage?: number;
  speed?: number;
  children: JSX.Element;
};
const Parallax: FunctionComponent<Props> = (props: Props) => {
  const percentage = props.percentage || 1.0;
  const speed = props.speed || -2;

  const ref = useRef(null);
  useEffect(() => {
    if (prefersReducedMotion()) {
      return;
    }
    const config = {};
    const rellax = new Rellax(ref.current, config);
    return () => {
      rellax.destroy();
    };
  }, [props.percentage, props.speed]);
  return (
    <div
      ref={ref}
      data-rellax-percentage={percentage}
      data-rellax-speed={speed}
    >
      {props.children}
    </div>
  );
};
export default Parallax;
