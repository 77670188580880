import { Link } from "gatsby";
import Hamburger from "../Hamburger/Hamburger";
import React, { FunctionComponent } from "react";

import Props from "./MobileHeaderProps.type";

import { ReactComponent as Logo } from "../../images/logo.svg";

const MobileHeader: FunctionComponent<Props> = props => {
  return (
    <div className="container flex justify-between items-center py-4 md:hidden">
      <Link to="/">
        <Logo />
      </Link>
      <Hamburger {...props} />
    </div>
  );
};

export default MobileHeader;
