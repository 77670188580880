import React, { FunctionComponent } from "react";
import NavigationItem from "./NavigationItem";
import NavigationGroup from "./NavigationGroup";
import { useStaticQuery, graphql } from "gatsby";
import tracker from "../../../tracker";
import Separator from "./Separator";
import Parallax from "../../Parallax/Parallax";

const Navigation: FunctionComponent = () => {
  const data = useStaticQuery(graphql`
    query {
      whoIsAtRisk: trackingJson(id: { eq: "navWhoIsAtRisk" }) {
        ...TrackingQuery
      }
      whyScreen: trackingJson(id: { eq: "navWhyScreen" }) {
        ...TrackingQuery
      }
      screeningAndDiagnosis: trackingJson(
        id: { eq: "navScreeningAndDiagnosis" }
      ) {
        ...TrackingQuery
      }
      screeningAndDiagnosis: trackingJson(
        id: { eq: "navScreeningAndDiagnosis" }
      ) {
        ...TrackingQuery
      }
      treatingPatients: trackingJson(id: { eq: "navTreatingPatients" }) {
        ...TrackingQuery
      }
      managingHepB: trackingJson(id: { eq: "navManagingHepB" }) {
        ...TrackingQuery
      }
      patientEducation: trackingJson(id: { eq: "navPatientEducation" }) {
        ...TrackingQuery
      }
      chbResources: trackingJson(id: { eq: "navChbResources" }) {
        ...TrackingQuery
      }
    }
  `);

  return (
    <nav className="TopFooter__Navigation container mt-4 lg:mt-0">
      <Parallax speed={1} percentage={0.5}>
        <div className="lg:flex lg:justify-between lg:items-start">
          <NavigationGroup className="mt-8 lg:mt-0">
            <NavigationItem
              href="/hepatitis-b-risk"
              tracking={tracker(data.whoIsAtRisk)}
            >
              Who is at Risk?
            </NavigationItem>
            <NavigationItem
              href="/hepatitis-b-risk/screening"
              tracking={tracker(data.whyScreen)}
            >
              Why Screen?
            </NavigationItem>
          </NavigationGroup>
          <Separator />
          <NavigationGroup>
            <NavigationItem
              href="/hepatitis-b-screening-diagnosis"
              tracking={tracker(data.screeningAndDiagnosis)}
            >
              Screening &amp; Diagnosis
            </NavigationItem>
          </NavigationGroup>
          <Separator />
          <NavigationGroup>
            <NavigationItem
              href="/hepatitis-b-treatment"
              tracking={tracker(data.treatingPatients)}
            >
              Treating Patients
            </NavigationItem>
          </NavigationGroup>
          <Separator />
          <NavigationGroup>
            <NavigationItem
              href="/hepatitis-b-management"
              tracking={tracker(data.managingHepB)}
            >
              Managing Hep B
            </NavigationItem>
            <NavigationItem
              href="/hepatitis-b-management/patient-education"
              tracking={tracker(data.patientEducation)}
            >
              Patient Education
            </NavigationItem>
          </NavigationGroup>
          <Separator />
          <NavigationGroup className="mb-8 lg:mb-0">
            <NavigationItem
              href="/hepatitis-b-resources"
              tracking={tracker(data.chbResources)}
            >
              CHB Resources
            </NavigationItem>
          </NavigationGroup>
        </div>
      </Parallax>
    </nav>
  );
};

export default Navigation;
