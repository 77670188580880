/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */
import React, { FunctionComponent } from "react";

import Header from "./Header";
import Footer from "./Footer/Footer";
import ScrollTracker from "./ScrollTracker";

const Layout: FunctionComponent = ({ children }) => {
  return (
    <ScrollTracker>
      <Header />
      <main>{children}</main>
      <Footer />
    </ScrollTracker>
  );
};

export default Layout;
