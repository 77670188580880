import React, { FunctionComponent } from "react";
import { mergeClassNames } from "../../../helpers";
import Link from "../../Link/Link";

type Props = {
  className?: string;
  href: string;
  tracking: any;
};

const NavigationItem: FunctionComponent<Props> = ({
  children,
  href,
  className,
  tracking,
}) => {
  return (
    <div className={mergeClassNames("my-2 first:my-0 lg:mt-0", className)}>
      <Link
        className="text-white hover:underline"
        to={href}
        tracking={tracking}
      >
        {children}
      </Link>
    </div>
  );
};

export default NavigationItem;
