import React, { FunctionComponent } from "react";
import { mergeClassNames } from "../../../helpers";
import MaybeClassProps from "../../MaybeClassProps.type";

const NavigationGroup: FunctionComponent<MaybeClassProps> = ({
  children,
  className,
}) => {
  return (
    <div className={mergeClassNames("TopFooter__Group flex", className)}>
      <div className="">{children}</div>
    </div>
  );
};

export default NavigationGroup;
