import { Link } from "gatsby";
import React, { FunctionComponent } from "react";

type Props = {
  to: string;
};

const DropdownNavigationItem: FunctionComponent<Props> = ({ to, children }) => {
  return (
    <li>
      <Link
        className="pb-2 text-white text-right block border-b-2 border-white-50 md:border-b-0 md:text-center md:p-4 md:uppercase md:text-black md:text-base transition-color md:hover:text-green"
        to={to}
        activeClassName="NavigationItem__anchor--active"
      >
        {children}
      </Link>
    </li>
  );
};

export default DropdownNavigationItem;
