import React, { FunctionComponent, MouseEvent } from "react";
import Paragraph from "./Paragraph";

const GILEAD = (
  <div className="h-24">
    <Paragraph>
      Click "Continue" to leave this site and come back to visit us another
      time. Click "Cancel" to return to HepBMD.com.
    </Paragraph>
  </div>
);

const NOT_GILEAD = (
  <>
    <Paragraph>
      You are about to leave HepBMD.com. Gilead is not responsible for the
      content of the site you are about to visit.
    </Paragraph>
    <Paragraph>
      Click "Continue" to leave this site and come back to visit us another
      time.
    </Paragraph>
    <Paragraph>Click "Cancel" to return to HepBMD.com.</Paragraph>
  </>
);

type Props = {
  to: string;
  isGilead?: boolean;
  onCancel: (event: MouseEvent) => void;
};
const Interstitial: FunctionComponent<Props> = ({ isGilead, to, onCancel }) => {
  return (
    <div className="modal fixed inset-x-0 top-0 z-20 text-left ">
      <div className="bg-white mt-16 mx-auto w-5/6 md:w-1/2 py-8 px-16 shadow-dark text-base">
        <h3 className="font-bold antialiased pb-4 text-lg">
          You are about to leave HepBMD.com.
        </h3>
        {isGilead ? GILEAD : NOT_GILEAD}
        <div className="pt-4">
          <a
            href={to}
            className="inline-block py-3 px-8 transition-bg bg-green hover:bg-green-dark text-white shadow-button mr-4 uppercase font-bold antialiased"
          >
            Ok
          </a>{" "}
          <a
            href="#"
            onClick={onCancel}
            className="inline-block text-green font-bold underline"
          >
            Cancel
          </a>
        </div>
      </div>
    </div>
  );
};

export default Interstitial;
