import { graphql } from "gatsby";

export const trackingQuery = graphql`
  fragment TrackingQuery on TrackingJson {
    event
    eventDescription
    eventCategory
    eventAction
    eventLabel
    interaction
    subject
    intendedAudience
    language
  }
`;

declare global {
  interface Window {
    dataLayer: any;
  }
}

export default function tracker(data: object) {
  return () => {
    window.dataLayer.push(data);
  };
}
