import Line from "./Line";
import React, { FunctionComponent } from "react";

import Props from "../MobileHeader/MobileHeaderProps.type";

const Hamburger: FunctionComponent<Props> = ({ openNavigation, open }) => {
  return (
    <button
      aria-controls="drawer-navigation"
      aria-expanded={open ? "true" : "false"}
      className="flex items-center"
      onClick={openNavigation}
    >
      <span className="uppercase text-sm mr-2 mt-1">MENU</span>
      <div className="w-6">
        <Line className="mt-0" />
        <Line className="mt-2" />
        <Line className="mt-2" />
      </div>
    </button>
  );
};

export default Hamburger;
