import React, { FunctionComponent } from "react";
import Navigation from "./Navigation";

import gileadLogo from "./gilead-logo.png";
import Paragraph from "../../Paragraph";

const BottomFooter: FunctionComponent = () => {
  return (
    <div className="bg-black-dark py-12">
      <div className="container">
        <Navigation />
        <a href="https://www.gilead.com">
          <img src={gileadLogo} alt="Gilead Sciences Logo" />
        </a>
        <Paragraph color="white" className="text-xs">
          Photos not of actual patients.
        </Paragraph>
        <Paragraph color="white" className="text-xs">
          THIS SITE IS INTENDED FOR US HEALTHCARE PROFESSIONALS ONLY. <br />
          HEPBMD, the HEPBMD.COM Logo, the Liver Icon, GILEAD, and the GILEAD
          Logo are trademarks of Gilead Sciences, Inc. or its related companies.
          All other trademarks referenced herein are the property of their
          respective owners.
        </Paragraph>
        <Paragraph color="white" className="text-xs">
          ©{new Date().getFullYear()} Gilead Sciences, Inc. All rights reserved.
          US-UNBP-2097 08/23
        </Paragraph>
      </div>
    </div>
  );
};

export default BottomFooter;
