import React, { FunctionComponent } from "react";
import { mergeClassNames } from "../../../helpers";

type Props = {
  className?: string;
  href: string;
};

const NavigationItem: FunctionComponent<Props> = ({
  children,
  href,
  className,
}) => {
  return (
    <li className={mergeClassNames("mt-2 first:mt-0 md:mt-0", className)}>
      <a className="text-white hover:underline" href={href}>
        {children}
      </a>
    </li>
  );
};

export default NavigationItem;
