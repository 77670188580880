import React, { FunctionComponent } from "react";
import Navigation from "./Navigation";

import "./TopFooter.scss";

const TopFooter: FunctionComponent = () => {
  return (
    <div className="TopFooter flex items-center pt-8 lg:h-64">
      <Navigation />
    </div>
  );
};

export default TopFooter;
