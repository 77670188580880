import React, { FunctionComponent, useState } from "react";
import { Link as GatsbyLink } from "gatsby";
import { mergeClassNames } from "../../helpers";
import Interstitial from "../Interstitial";

type ExtraLinkProps = {
  target?: string;
  rel?: string;
};

type LinkProps = {
  className?: string;
  download?: boolean;
  to: string;
  external?: boolean;
  isGilead?: boolean;
  openInNewTab?: boolean;
  jump?: boolean;
  tracking: Function;
};

const Link: FunctionComponent<LinkProps> = ({
  children,
  className,
  download,
  external,
  isGilead,
  to,
  openInNewTab,
  jump,
  tracking,
}) => {
  const [modalOpen, setModalOpen] = useState(false);

  const htmlAttributes = () => {
    const attributes: ExtraLinkProps = {};
    if (openInNewTab) {
      attributes.target = "_blank";
      attributes.rel = "noopener noreferrer";
    }
    return attributes;
  };
  const renderTag = (children: JSX.Element) => {
    if (external) {
      return (
        <>
          <a href={to} onClick={showModal} {...htmlAttributes()}>
            {children}
          </a>
          {modalOpen && (
            <Interstitial to={to} isGilead={isGilead} onCancel={hideModal} />
          )}
        </>
      );
    } else if (jump) {
      return (
        <a href={to} onClick={tracking}>
          {children}
        </a>
      );
    } else {
      if (download) {
        return (
          <a href={to} download {...htmlAttributes()}>
            {children}
          </a>
        );
      }
      return (
        <GatsbyLink to={to} {...htmlAttributes()} onClick={tracking}>
          {children}
        </GatsbyLink>
      );
    }
  };

  return renderTag(
    <span className={mergeClassNames(className)}>{children}</span>
  );

  function showModal(event: React.MouseEvent) {
    tracking();
    setModalOpen(true);
    event.preventDefault();
    return false;
  }
  function hideModal(event: React.MouseEvent) {
    setModalOpen(false);
    event.preventDefault();
    return false;
  }
};
export default Link;
