import TopFooter from "./TopFooter/TopFooter";
import React, { FunctionComponent } from "react";
import BottomFooter from "./BottomFooter/BottomFooter";

const Footer: FunctionComponent = () => {
  return (
    <footer className="relative -mt-16">
      <TopFooter />
      <BottomFooter />
    </footer>
  );
};

export default Footer;
