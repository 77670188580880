import React, { FunctionComponent } from "react";
import NavigationItem from "./NavigationItem";

const Navigation: FunctionComponent = () => {
  return (
    <nav className="my-8 md:mt-20">
      <ul className="md:flex md:justify-between md:items-center md:w-4/5">
        <NavigationItem href="https://www.gilead.com/about">
          About Us
        </NavigationItem>
        <NavigationItem href="https://www.gilead.com/privacy-statements">
          Privacy Policy
        </NavigationItem>
        <NavigationItem href="https://www.gilead.com/privacy-statements/consumer-health-data-privacy-policy">
          Consumer Health Data Privacy Policy
        </NavigationItem>
        <NavigationItem href="https://www.gilead.com/Terms">
          Terms of Use
        </NavigationItem>
        <NavigationItem href="https://www.gilead.com/contact">
          Contact Us
        </NavigationItem>
      </ul>
    </nav>
  );
};

export default Navigation;
